
import { defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Register",
  components: {},
  setup() {
    const email = ref("");
    const password = ref("");
    const repeatPassword = ref("");
    const error = ref(false);
    const errorMessage = ref("");
    const buttonText = ref("Register");
    const isButtonDisable = ref(true);
    const isAgree = ref(false);

    const store = useStore();
    const router = useRouter();

    const getCookie = (name: string) => {
      const matches = document.cookie.match(
        new RegExp(
          `(?:^|; )${name.replace(
            // eslint-disable-next-line no-useless-escape
            /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
            "\\$1"
          )}=([^;]*)`
        )
      );
      return matches ? decodeURIComponent(matches[1]) : null;
    };

    const register = () => {
      error.value = false;
      errorMessage.value = "";
      if (password.value === repeatPassword.value) {
        buttonText.value = "Wait...";
        isButtonDisable.value = true;
        const referral = getCookie("link");
        store
          .dispatch("auth/register", {
            email: email.value,
            password: password.value,
            ref: referral,
          })
          .then(
            () => {
              router.push({ name: "Home" });
            },
            (error) => {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const key in error.data.errors) {
                if (key === "email" && error.data.errors[key][0] === "email.exist") {
                  errorMessage.value += "Email already registered";
                }
                if (key === "email" && error.data.errors[key][0] === "error.email") {
                  errorMessage.value += "Email not valid";
                }
              }
              buttonText.value = "Register";
              isButtonDisable.value = false;
            }
          );
      } else {
        errorMessage.value += "Passwords don't match";
      }
      error.value = true;
    };

    watch(isAgree, () => {
      isButtonDisable.value = !isAgree.value;
    });

    return {
      email,
      password,
      repeatPassword,
      error,
      errorMessage,
      buttonText,
      isButtonDisable,
      isAgree,
      register,
    };
  },
});
